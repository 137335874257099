<template>
  <div>
    <b-card>
      <b-row>
        <b-col lg="6">
          <b-overlay
            :show="loadingOverlay"
            rounded="sm"
          >
            <validation-observer ref="simpleRules">
              <b-form @submit.prevent="submitForm">
                <b-row>
                  <b-col
                    lg="12"
                    class="mb-2"
                  >
                    <h5>{{ $t('spot.cambio.titleForm') }}</h5>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="6">
                    <b-form-group>
                      <label for="trocar-moeda">{{ $t('spot.cambio.labelTroca') }}</label>
                      <validation-provider
                        #default="{ errors }"
                        name="Trocar"
                        rules="required"
                      >
                        <b-form-select
                          v-model="data.moedaFrom"
                          :options="optionsFrom"
                          input-id="trocar-moeda"
                          :state="errors.length > 0 ? false:null"
                          @change="alteraMoedaFrom"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                      <div
                        v-if="data.moedaFrom!=null"
                        class="font-weight-bolder mt-1"
                      >
                        {{ $t('spot.cambio.textDisponivel') }}
                        <div class="text-success">
                          {{ saldo }} {{ data.moedaFrom }}
                        </div>
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="6">
                    <b-form-group>
                      <label for="trocar-por">{{ $t('spot.cambio.labelPor') }}</label>
                      <validation-provider
                        #default="{ errors }"
                        name="Por"
                        rules="required"
                      >
                        <b-form-select
                          v-model="data.moedaTo"
                          :options="optionsTo"
                          input-id="trocar-por"
                          :state="errors.length > 0 ? false:null"
                          @change="alteraMoedaTo"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="6">
                    <b-form-group>
                      <label for="valor">
                        {{ $t('spot.cambio.labelValorPrimeiraParte') }}
                        <span class="font-weight-bolder">{{ data.moedaFrom }}</span>
                        {{ $t('spot.cambio.labelValorSegundaParte') }}
                        <span class="font-weight-bolder">{{ data.moedaTo === '' ?'...' : data.moedaTo }}</span>
                      </label>
                      <validation-provider
                        #default="{ errors }"
                        name="Valor a trocar"
                        :rules="{ required: true, regex: /^[+]?\d+(\.\d+)?$/, min_value: moedaFromMinValue }"
                      >
                        <b-form-input
                          id="valor"
                          v-model="data.quantia"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>

                        <div
                          v-if="data.moedaTo && data.quantia != 0 && errors.length == 0"
                          class="font-weight-bolder mt-1"
                        >
                          {{ $t('spot.cambio.textRecebaAprox') }}
                          <h3 class="text-success">
                            {{ quantidadeAproximada }} {{ data.moedaTo }}
                          </h3>
                        </div>
                      </validation-provider>

                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="6">
                    <b-button
                      variant="primary"
                      type="submit"
                    >
                      {{ $t('spot.cambio.trocaBtn') }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-overlay>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>

import {
  BRow, BButton, BOverlay, BCol, BFormGroup, BCard, BFormSelect, BForm, BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, minValue } from '@validations'
import store from '@/store'
import utilsMixin from '@/mixins/utilsMixin'
import Ripple from 'vue-ripple-directive'
import { subscribeSymbol } from '@/services/binance/spot/binance'
import cambioStoreModule from './cambioStoreModule'

const CAMBIO_SPOT_STORE_MODULE_NAME = 'cambio-spot'

export default {

  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BOverlay,
    BCard,
    BFormSelect,
    BForm,
    BFormInput,
  },

  directives: {
    Ripple,
  },

  mixins: [utilsMixin],

  data() {
    return {
      loadingOverlay: false,
      dataResponse: {
        status: null,
      },
      data: {
        moedaFrom: null,
        moedaTo: null,
        quantia: '',
      },
      coin: '',
      saldo: '',
      quantidade: 0,
      required,
      minValue,
    }
  },

  computed: {
    optionsFrom() {
      return [
        { value: null, text: this.$i18n.t('spot.cambio.placeHolderPrimeiroCampo') },
        { value: 'BTC', text: 'BTC' },
        { value: 'USDT', text: 'USDT' },
        { value: 'BRL', text: 'BRL' },
      ]
    },
    moedaFromMinValue() {
      let min = 10
      if (this.data.moedaFrom === 'USDT' || this.data.moedaFrom === 'BRL') {
        min = 10
      } else if (this.data.moedaFrom === 'BTC') {
        min = 0.0002
      }
      return min
    },

    optionsTo() {
      const options = []
      options.push({ value: null, text: this.$i18n.t('spot.cambio.placeHolderPrimeiroCampo') })

      if (this.data.moedaFrom !== 'BTC') {
        options.push({ value: 'BTC', text: 'BTC' })
      }

      if (this.data.moedaFrom !== 'BRL') {
        options.push({ value: 'BRL', text: 'BRL' })
      }
      if (this.data.moedaFrom !== 'BNB') {
        options.push({ value: 'BNB', text: 'BNB' })
      }
      if (this.data.moedaFrom !== 'USDT') {
        options.push({ value: 'USDT', text: 'USDT' })
      }
      return options
    },

    tickers() {
      return this.$store.state.binanceSpot.tickers
    },

    quantidadeAproximada() {
      let temp = ''
      if (this.tickers) {
        if (this.tickers[this.coin]) {
          const coinReal = this.data.moedaFrom + this.data.moedaTo
          if (coinReal === 'USDTBNB' || coinReal === 'USDTBTC' || coinReal === 'BTCBNB' || coinReal === 'BRLBTC' || coinReal === 'BRLBNB') {
            temp = this.data.quantia / this.tickers[this.coin].price
          } else if (coinReal === 'BNBBRL' || coinReal === 'BTCBRL' || coinReal === 'USDTBRL') {
            temp = this.data.quantia * this.tickers[this.coin].price
          } else {
            temp = this.tickers[this.coin].price / this.data.quantia
          }
          return temp.toFixed(6)
        }
      }
      return temp
    },

  },

  created() {
    if (!store.hasModule(CAMBIO_SPOT_STORE_MODULE_NAME)) store.registerModule(CAMBIO_SPOT_STORE_MODULE_NAME, cambioStoreModule)
  },

  beforeDestroy() {
    if (store.hasModule(CAMBIO_SPOT_STORE_MODULE_NAME)) store.unregisterModule(CAMBIO_SPOT_STORE_MODULE_NAME)
  },

  methods: {

    novaOperacao() {
      this.dataResponse.status = null
    },

    alteraMoedaTo() {
      if (this.data.moedaFrom == null || this.data.moedaTo == null) {
        return
      }

      this.coin = this.data.moedaFrom + this.data.moedaTo

      if (this.coin === 'BTCBNB') {
        this.coin = 'BNBBTC'
      } else if (this.coin === 'BRLBTC') {
        this.coin = 'BTCBRL'
      } else if (this.coin === 'BRLBNB') {
        this.coin = 'BNBBRL'
      } else if (this.coin === 'USDTBTC') {
        this.coin = 'BTCUSDT'
      } else if (this.coin === 'USDTBNB') {
        this.coin = 'BNBUSDT'
      }
      subscribeSymbol(this.coin)
    },

    alteraMoedaFrom() {
      this.data.quantia = 0
      this.data.moedaTo = null
      this.data.quantidade = 0
      this.getSaldo(this.data.moedaFrom)
    },

    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.loadingOverlay = true
          this.$store.dispatch('cambio-spot/converter', this.data)
            .then(res => {
              this.$swal({
                icon: 'success',
                title: 'Troca efetuada',
                html: `Você acabou de trocar <strong>${res.data.executedQty} ${res.data.moedaFrom}</strong> por <strong>${res.data.cummulativeQuoteQty} ${res.data.moedaTo}</strong>`,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
                buttonsStyling: false,
              })
              this.data.quantia = ''
              this.data.moedaTo = null
              this.data.moedaFrom = null
              this.dataResponse = res.data
              this.$refs.simpleRules.reset()
            })
            .catch(error => {
              this.$swal({
                title: 'Error!',
                text: error.data,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .finally(() => {
              this.loadingOverlay = false
            })
        }
      })
    },

    getSaldo(moeda) {
      this.loadingOverlay = true
      this.$store.dispatch('cambio-spot/getSaldo', moeda)
        .then(res => { this.saldo = res.data })
        .finally(() => {
          this.loadingOverlay = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div ::v-deep .card{
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>
